<template>
  <div>
    <PostFormComp pageTitle="Testimonial" hideDetail="1" thumenailDescription="ขนาดรูปแนะนำ 400x400 pixel" />
  </div>
</template>

<script>
import PostFormComp from '@/components/content/post/PostFormComp.vue';

export default {
  name: 'PostFormHomeGuru',
  components: {
    PostFormComp
  },
  created() {
    this.$store.commit('setPageTitle', 'Testimonial');
    this.$store.commit('setHeadTitle', 'Testimonial');
  },
}
</script>